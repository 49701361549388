/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ScheduleLocationData, ScheduleStationData } from "../types/schedule";

interface ScheduleState {
	submissionDetails: Record<string, any> | any;
	appointmentStatusDetails: Record<string, any> | null;
	scheduleCode: string;
	selectedStation: string;
	selectedStationDetails: string;
	appointmentDetails: any[];
	appointmentCustomFields: any[];
	appointmentStationDetails: ScheduleStationData | null;
	appointmentLocationDetails: ScheduleLocationData | null;
	step: number;
	setStep: (step: number) => void;
	setSubmissionDetails: (submissionDetails: Record<string, any>) => void;
	setAppointmentStatusDetails: (
		appointmentStatusDetails: Record<string, any>
	) => void;
	setScheduleCode: (code: string) => void;
	setSelectedStation: (station: string) => void;
	setSelectedStationDetails: (details: string) => void;
	setAppointmentDetails: (details: any[]) => void;
	setAppointmentCustomFields: (fields: any[]) => void;
	setAppointmentStationDetails: (details: ScheduleStationData) => void;
	setAppointmentLocationDetails: (details: ScheduleLocationData) => void;
}

const initialState = {
	submissionDetails: {},
	appointmentStatusDetails: null,
	scheduleCode: "",
	selectedStation: "",
	selectedStationDetails: "",
	appointmentDetails: [],
	appointmentCustomFields: [],
	appointmentStationDetails: null,
	appointmentLocationDetails: null,
	step: 0,
};

const useScheduleStore = create<ScheduleState>()(
	persist(
		(set) => ({
			...initialState,
			setSubmissionDetails: (details) =>
				set({ submissionDetails: details }),
			setAppointmentStatusDetails: (details) =>
				set({ appointmentStatusDetails: details }), // Add this line
			setScheduleCode: (code) => set({ scheduleCode: code }),
			setSelectedStation: (station) => set({ selectedStation: station }),
			setSelectedStationDetails: (details) =>
				set({ selectedStationDetails: details }),
			setAppointmentDetails: (details) =>
				set({ appointmentDetails: details }),
			setAppointmentCustomFields: (fields) =>
				set({ appointmentCustomFields: fields }),
			setAppointmentStationDetails: (details) =>
				set({ appointmentStationDetails: details }),
			setAppointmentLocationDetails: (details) =>
				set({ appointmentLocationDetails: details }),
			setStep: (step) => set({ step }),
		}),
		{
			name: "schedule-store",
			getStorage: () => localStorage,
		}
	)
);

export default useScheduleStore;
