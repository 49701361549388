import { ScheduleFormProvider } from "./helpers/ScheduleFormContext";
import BookTime from "./BookTime";
import DetailsForm from "./DetailsForm";
import AppointmentConfirmation from "./AppointmentConfirmation";
import useScheduleStore from "@/src/store/useScheduleStore";

const ScheduleAppointment = () => {
	const { step, setStep } = useScheduleStore();

	return (
		<>
			<ScheduleFormProvider>
				{step == 0 && <BookTime step={step} setStep={setStep} />}
				{step == 1 && <DetailsForm step={step} setStep={setStep} />}
				{step == 2 && <AppointmentConfirmation />}
			</ScheduleFormProvider>
		</>
	);
};

export default ScheduleAppointment;
