import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import BrowserRouter as Router
import useMediaQuery from "../hooks/useMediaQuery";
import Layout from "../layouts/Layout";
import App from "./App";
import ReschelduleAppointment from "./Schedule/Reschedule";
import DoneWaitlist from "./Waitlist/Done";
import JoinWaitList from "./Waitlist/JoinWaitlist";
import LeftWaitlist from "./Waitlist/Left";
import ViewWaitList from "./Waitlist/ViewWaitList";
import LocationWaitlist from "./Waitlist/LocationWaitlist";
import IntakeForm from "./Schedule/IntakeForm";
import Schedule from "./Schedule/Schedule";
import ScheduleAppointment from "./Schedule/ScheduleAppointment";
import AppointmentStatus from "./Schedule/AppointmentStatus";
import ApppointmentFinished from "./Schedule/ApppointmentFinished";

const AppRoutes: React.FC = () => {
	const isMobile = useMediaQuery("(max-width: 768px)");
	// const RequireAuth: React.FC = () => {
	// 	const location = useLocation();
	// 	const user = true;

	// 	if (!user) {
	// 		return (
	// 			<Navigate to="/sign-in" state={{ from: location }} replace />
	// 		);
	// 	}

	// 	return <Outlet />;
	// };

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />} />
				<Route element={<Layout />}>
					{/* Waitlist Start */}
					{/* {New} */}
					<Route path="/waitlist" element={<JoinWaitList />} />
					<Route
						path="/waitlist/location"
						element={<LocationWaitlist />}
					/>
					<Route
						path="/waitlist/location/:location_join_code"
						element={<LocationWaitlist />}
					/>
					<Route
						path="/waitlist/:join_code"
						element={<JoinWaitList />}
					/>
					<Route
						path="/waitlist/view/:waitlist_code"
						element={<ViewWaitList />}
					/>
					<Route
						path="/waitlist/:waitlist_code/done"
						element={<DoneWaitlist />}
					/>
					<Route
						path="/waitlist/:waitlist_code/leave"
						element={<LeftWaitlist />}
					/>

					{/* <Route
						path="/waitlist/:id/register"
						element={<WaitListRegister />}
					/> */}
					{/* <Route path="/wailist/:id/" element={<SwapList />} /> */}
					{/* <Route path="/wailist/:id/feedback" element={<Rating />} /> */}
					{/* Waitlist End*/}

					{/* Appointment Start*/}
					<Route
						path="/schedule/:scheduling_code"
						element={<Schedule />}
					/>
					<Route
						path="/schedule/:appointment_code/intake-form"
						element={<IntakeForm />}
					/>
					<Route
						path="/schedule/:scheduling_code/create-appointment"
						element={<ScheduleAppointment />}
					/>
					<Route
						path="/schedule/:appointment_code/status"
						element={<AppointmentStatus />}
					/>
					<Route
						path="/schedule/:appointment_code/done"
						element={<ApppointmentFinished />}
					/>

					{/* <Route
						path="/schedule/:id/book-time"
						element={<AppointmentSchelduleTime />}
					/>
					<Route
						path="/schedule/:id/details"
						element={<AppointmentDetails />}
					/> */}
					<Route
						path="/schedule/:appointment_code/reschedule"
						element={<ReschelduleAppointment />}
					/>

					{/* Appointment End*/}
				</Route>
			</Routes>
			<Toaster
				position={isMobile ? "top-center" : "bottom-center"}
				toastOptions={{
					style: {
						maxWidth: "1000px",
					},
				}}
			/>
		</BrowserRouter>
	);
};

export default AppRoutes;
