import { useUpdateWaitlist } from "@/src/store/slices/waitlist";
import {
	UpdateWaitlistActionType,
	UpdateWaitlistSchema,
} from "@/src/types/waitlist";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useCustomToast from "../CustomToast";
import { LoaderButton } from "../ui-extended/loader-button";
import { Dialog, DialogContent } from "../ui/dialog";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import StarRating from "./Rating";
import { useNavigate } from "react-router";
import useUserStore from "@/src/store/useUserStore";
import { useQueryClient } from "@tanstack/react-query";

const RateExperience: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [showTextArea, setShowTextArea] = React.useState(false);
	const { reset } = useUserStore((s) => ({
		reset: s.reset,
	}));
	const customToast = useCustomToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const updateWaitlistMutation = useUpdateWaitlist(
		() => {
			customToast("Thanks for the feedback 😊", {
				id: "update-waitlist",
			});
			setIsOpen(true);
			setTimeout(() => {
				reset();
				navigate("/");
				queryClient.invalidateQueries({ queryKey: ["waitlist-info"] });
			}, 2500);
		},
		() => {
			customToast("Could not submit feedback 🤕", {
				id: "update-waitlist",
				type: "error",
			});
		}
	);

	const {
		handleSubmit,
		formState: { errors },
		register,
		setValue,
	} = useForm<UpdateWaitlistActionType>({
		defaultValues: { action: "rate" },
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const onSubmit: SubmitHandler<UpdateWaitlistActionType> = (data) => {
		updateWaitlistMutation.mutate(data);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col items-stretch space-y-8 rounded-xl bg-white p-6"
			>
				<div>
					<h1 className="text-[28px] font-bold leading-[140%] tracking-[-0.1.4px] text-main-1">
						Rate your experience
					</h1>
					<p className="text-sm tracking-[0.07px] text-main-7">
						Leave a review of your experience at for North York City
						Center Medical Clinic.
					</p>
				</div>
				<div>
					<StarRating
						onRatingChange={(rating) => {
							if (!showTextArea) setShowTextArea(true);
							setValue("rating", rating);
						}}
					/>
					{errors.rating?.message && (
						<small className="text-sm text-destructive">
							{errors.rating?.message as string}
						</small>
					)}
				</div>
				{showTextArea && (
					<div className="space-y-1.5">
						<Label className="text-main-1">Add Feedback</Label>
						<Textarea
							className="min-h-[124px]"
							{...register("comment")}
						/>
						{errors.comment?.message && (
							<small className="text-sm text-destructive">
								{errors.comment?.message as string}
							</small>
						)}
					</div>
				)}

				{/* <Button className="text-white">Submit</Button> */}
				<LoaderButton
					className="text-white"
					type="submit"
					loaderSize={20}
					onClick={() => handleSubmit(onSubmit)}
					loading={updateWaitlistMutation.isPending}
					disabled={updateWaitlistMutation.isPending || !showTextArea}
				>
					Submit
				</LoaderButton>
			</form>
			<Dialog open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent className="flex w-full flex-col items-center space-y-2 rounded-[10px]">
					<img
						src="/assets/graphics/icons/check.svg"
						alt="Check"
						className="mx-1.5 size-[52px]"
					/>
					<h1 className="trackking-[-1%] text-xl font-medium text-main-1">
						Thank you for your review!
					</h1>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default RateExperience;
