import React, { useEffect, useState } from "react";
import FormLayout from "./components/FormLayout";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import TimeSlot from "./components/TimeSlot";
import { Calendar } from "@/src/components/ui/calendar";
import { useStationAvailableTimeSlots } from "@/src/store/slices/scheduleSlice";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { cn } from "@/src/utils/general";
import Loader from "@/src/components/Loader/Loader";
import moment from "moment";
import CustomSelect from "@/src/components/common/CustomSelect";

interface BookTimeProps {
	step: 0 | 1 | 2 | 3;
	setStep: (step: number) => void;
}

const BookTime = ({ step, setStep }: BookTimeProps) => {
	const { appointmentStationDetails } = useScheduleStore();
	const { formData, setFormData } = useFormContext();
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		formData?.appointmentDetails?.date
			? new Date(formData?.appointmentDetails?.date)
			: new Date()
	);
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [selectedAppointmentType, setSelectedAppointmentType] =
		useState<any>(null);
	const formattedDate = selectedDate
		? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
		: undefined;

	const {
		data: timeslotsData,
		isLoading: isTimeSlotsLoading,
		error,
	} = useStationAvailableTimeSlots(appointmentStationDetails?.station.id, {
		appointment_type_id: selectedAppointmentType?.value,
		date: formattedDate,
	});

	const timeslots =
		timeslotsData?.data || appointmentStationDetails?.available_time_slots;

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	}, []);
	useEffect(() => {
		setSelectedTime(null);
	}, [formattedDate]);

	const handleAppointmentTypeChange = (selectedOption: any) => {
		setSelectedAppointmentType(selectedOption);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				appointmentTypeId: selectedOption?.value,
			},
		}));
	};

	const handleDateChange = (date: Date | undefined) => {
		if (!date) return;

		const formattedDate = moment(date).startOf("day").format("YYYY-MM-DD");
		setSelectedDate(date);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	};

	const handleTimeSlotChange = (time: string) => {
		setSelectedTime(time);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				timeSlot: time,
			},
		}));
	};
	return (
		<FormLayout step={step} setStep={setStep}>
			<div className="bg-primary-3 flex flex-col gap-y-4 px-6 pb-12 pt-6 font-hoves md:h-[100svh] md:overflow-hidden lg:h-auto lg:px-12 2xl:h-screen">
				<div className="h-full w-full items-center justify-center gap-6 align-top md:flex md:items-start">
					<div className="flex flex-col gap-y-3 ">
						<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
							<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-sm font-semibold text-main-1">
									{
										appointmentStationDetails?.station
											?.location?.name
									}
								</p>
							</div>
							<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
								<i className="mgc_location_line before:!text-primary" />
								<p className="text-sm text-[#6D748D]">
									{
										appointmentStationDetails?.station
											?.location?.address
									}
								</p>
							</div>
							<div className="flex items-center gap-x-2">
								<i className="mgc_store_line before:!text-[#043B6D]" />
								<p className="text-sm text-main-1">
									{appointmentStationDetails?.station?.name}
								</p>
							</div>
						</div>
						<div className=" border-primary-3 my-3 flex flex-col gap-y-1 rounded-[12px] bg-white px-4 py-4">
							<p className="font-medium text-main-1">
								Select Appointment Type
							</p>
							<CustomSelect
								className="w-full"
								placeholder="Appointment Type"
								options={appointmentStationDetails?.appointment_types?.map(
									(type) => ({
										value: type.id,
										label: type.title,
									})
								)}
								value={selectedAppointmentType}
								onChange={handleAppointmentTypeChange}
							/>
						</div>
						<div className="w-full  justify-center rounded-md border bg-white px-4 py-2">
							<h5 className="font-medium text-main-1 ">
								Select Your Appointment Date
							</h5>
							<div className="flex justify-center">
								<Calendar
									mode="single"
									className="text-[20px]"
									styles={{
										head_cell: {
											width: "50px",
										},
										cell: {
											width: "50px",
										},
										table: {
											maxWidth: "none",
										},
										day: {
											margin: "auto",
										},
									}}
									selected={selectedDate}
									onSelect={handleDateChange}
								/>
							</div>
						</div>
					</div>

					<div className="w-full ">
						<div className=" h-auto w-full rounded-[12px] bg-white px-6 py-6 font-hoves">
							<p className="text-base font-medium leading-[30px] -tracking-1% text-main-1 md:text-[22px]">
								Select your Appointment Time
							</p>
							<>
								{isTimeSlotsLoading && (
									<div className="mx-auto my-3 flex h-full w-full items-center justify-center self-center">
										<Loader size={25} />
									</div>
								)}

								{error && (
									<p className="w-full py-4 text-center">
										Error loading time slots
									</p>
								)}

								{!isTimeSlotsLoading &&
								!error &&
								timeslots?.length > 1 ? (
									<div className="mt-4 grid grid-cols-2 gap-4  lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
										{timeslots?.map(
											(timeslot: any, i: number) => (
												<TimeSlot
													selected={
														selectedTime ===
															timeslot.start_time ||
														formData
															?.appointmentDetails
															?.timeSlot ===
															timeslot.start_time
													}
													onClick={() =>
														handleTimeSlotChange(
															timeslot.start_time
														)
													}
													className="w-[144px]"
													timeslot={timeslot}
													key={i}
												/>
											)
										)}
									</div>
								) : (
									<p
										className={cn("py-4", {
											hidden: isTimeSlotsLoading || error,
										})}
									>
										No timeslots available for this date.
									</p>
								)}
							</>
						</div>
					</div>
				</div>

				<div className="">
					<Button
						className="mt-6 w-full bg-[#043B6D] px-6 py-1.5"
						type="button"
						disabled={
							!formData?.appointmentDetails?.date ||
							!formData?.appointmentDetails?.timeSlot
						}
						onClick={() => setStep(1)}
					>
						Continue
					</Button>
				</div>
			</div>
		</FormLayout>
	);
};

export default BookTime;
