import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import Checkbox from "@/src/components/ui-extended/checkbox";
import { Button } from "@/src/components/ui/button";
import { Label } from "@/src/components/ui/label";
import { useGetLocationJoinInfo } from "@/src/store/slices/waitlist";
import useUserStore from "@/src/store/useUserStore";
import { JoinWaitlistLocationStationInfo } from "@/src/types/waitlist";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import WaitlistClosed from "./WaitlistClosed";
import WaitlistOperatingHoursClosed from "./WaitlistOperatingHoursClosed";

const LocationWaitlist: React.FC = () => {
	const navigate = useNavigate();
	const { location_join_code } = useParams();
	const getLocationoinInfoQuery = useGetLocationJoinInfo();
	const setLocationJoinCode = useUserStore((s) => s.setLocationJoinCode);
	const [selectedStation, setSelecteStation] =
		React.useState<JoinWaitlistLocationStationInfo>();

	useEffect(() => {
		setLocationJoinCode(location_join_code ?? "");
	}, []);

	if (!location_join_code) navigate("/");
	if (
		!getLocationoinInfoQuery.isLoading &&
		getLocationoinInfoQuery?.error?.response?.data.error ===
			"NO_WORKING_HOURS"
	) {
		return (
			<WaitlistOperatingHoursClosed
				query={getLocationoinInfoQuery?.error}
			/>
		);
	}
	if (
		!getLocationoinInfoQuery.isLoading &&
		getLocationoinInfoQuery?.error?.response?.data.error
	) {
		return <WaitlistClosed />;
	}

	return (
		<>
			{!getLocationoinInfoQuery.isLoading && (
				<section className="mt-14 flex flex-col items-center space-y-16 px-4">
					<img
						src="/assets/welcome.svg"
						alt="Welcome image"
						className="max-w-[246px]"
					/>
					<div className="w-full rounded-xl bg-white p-6">
						<div className="flex flex-col space-y-5">
							<h1 className="text-[28px] font-bold leading-[140%] tracking-[-0.14px] text-main-1">
								Welcome
							</h1>
							<ul className="flex flex-col space-y-2">
								<li className="flex items-center space-x-2">
									<i className="mgc_building_5_line before:!text-primary" />
									<p className="font-bold text-main-1">
										{
											getLocationoinInfoQuery?.data?.data
												.name
										}
									</p>
								</li>
								<li className="flex items-center space-x-2">
									<i className="mgc_location_line before:!text-primary" />
									<p className="text-main-1">
										{
											getLocationoinInfoQuery?.data?.data
												.address
										}
									</p>
								</li>
							</ul>
						</div>
						<div className="mt-10 flex flex-col space-y-2">
							<h2 className="font-bold leading-[140%] tracking-[-0.14px] text-main-1">
								Select which waitlist to join
							</h2>

							{getLocationoinInfoQuery?.data?.data.stations.map(
								(station) => (
									<LocationWaitlistCard
										key={station.id}
										station={station}
										isChecked={
											selectedStation?.id === station.id
										}
										onClick={() =>
											setSelecteStation(station)
										}
									/>
								)
							)}
						</div>
						<Button
							className="mt-8 w-full"
							type="button"
							onClick={() => {
								navigate(
									"/waitlist/" +
										selectedStation?.waitlist_setting
											.url_code
								);
							}}
							disabled={!selectedStation}
						>
							Join Waitlist
						</Button>
					</div>
				</section>
			)}

			<div />
			<RequestIsLoading
				isLoading={getLocationoinInfoQuery.isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default LocationWaitlist;

const LocationWaitlistCard: React.FC<{
	isChecked: boolean;
	station: JoinWaitlistLocationStationInfo;
	onClick: () => void;
}> = ({ station, isChecked, onClick }) => {
	return (
		<li
			className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-[#F1F3FC] p-2"
			onClick={onClick}
		>
			<div className="flex items-center justify-between space-x-1">
				<Label className="text-sm font-medium text-[#09090B]">
					{station.name}
				</Label>
				<Checkbox
					isChecked={isChecked}
					id={station.id.toString()}
					className="rounded border-[#D9D9D9]"
					handleCheckboxChange={onClick}
				/>
			</div>
			<p className="text-xs text-main-7">
				Lorem ipsum dolor sit amet consectetur. Nunc diam ipsum faucibus
				mi nibh turpis elementum faucibus.
			</p>
		</li>
	);
};
