import {
	APIVersion2CreateAppointment,
	APIVersion2GetScheduleLocationInfo,
	APIVersion2GetScheduleStationInfo,
	APIVersion2GetScheduleStatus,
	APIVersion2GetStationAvailableTimeSlotsForSchedule,
	APIVersion2UpdateAppointment,
} from "@/src/http/v1";
import { ScheduleStationResponse } from "@/src/types/schedule";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useScheduleStationInfo = (data: {
	scheduling_code?: string;
	station_id?: string;
}) => {
	return useQuery<ScheduleStationResponse>({
		queryKey: ["scheduleStationInfo", data],
		queryFn: () => APIVersion2GetScheduleStationInfo(data),
	});
};

export const useScheduleLocationInfo = (data: {
	scheduling_code?: string;
	location_id?: string;
}) => {
	return useQuery({
		queryKey: ["scheduleLocationInfo", data],
		queryFn: () => APIVersion2GetScheduleLocationInfo(data),
	});
};

export const useGetScheduleData = (data: { scheduling_code?: string }) => {
	return useQuery({
		queryKey: ["scheduleStatus", data],
		queryFn: () => APIVersion2GetScheduleStatus(data),
	});
};

export const useStationAvailableTimeSlots = (
	stationId: number | undefined,
	data: {
		appointment_type_id?: number;
		date: string | undefined;
	}
) => {
	return useQuery({
		queryKey: ["stationAvailableTimeSlots", stationId, data],
		queryFn: () =>
			APIVersion2GetStationAvailableTimeSlotsForSchedule(stationId, data),
	});
};

export const useCreateAppointment = () => {
	return useMutation({
		mutationFn: ({
			scheduling_code,
			data,
		}: {
			scheduling_code: string;
			data: {
				full_name: string;
				phone_number: string;
				email: string | null;
				date: string;
				start_time: string;
			};
		}) => APIVersion2CreateAppointment(scheduling_code, data),
	});
};

export const useUpdateAppointment = () => {
	return useMutation({
		mutationFn: ({
			appointmentId,
			data,
		}: {
			appointmentId: string;
			data: {
				reschedule_date?: string;
				reschedule_time?: string;
				action:
					| "done"
					| "cancel"
					| "reschedule"
					| "update_custom_intakes"
					| "check_in"
					| "undo_checkin";
				custom_intakes?: Record<string, any>;
			};
		}) => APIVersion2UpdateAppointment(appointmentId, data),
	});
};
