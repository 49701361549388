import useCustomToast from "@/src/components/CustomToast";
import { UseQueryError } from "@/src/hooks/useQueryError";
import {
	APIVersion1JoinWaitlist,
	APIVersion1UpdateWaitlist,
	APIVersion2GetJoinInfo,
	APIVersion2GetWaitlistInfo,
} from "@/src/http/v1";
import {
	JoinWaitlistLocationData,
	JoinWaitlistSingleStation,
	JoinWaitlistType,
	JoinWorkingHoursErrorResponse,
	UpdateWaitlistActionType,
	WaitlistCodeInfoResponse,
} from "@/src/types/waitlist";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router";
import useUserStore from "../useUserStore";

export const useGetWaitlistInfo = (
	onError: (
		error: AxiosError<JoinWorkingHoursErrorResponse> | null
	) => void = () => {
		return;
	}
) => {
	const { waitlist_code } = useParams();
	const setUser = useUserStore((s) => s.setUser);

	const getWaitlistInfoQuery = useQuery<
		AxiosResponse<WaitlistCodeInfoResponse>,
		AxiosError<JoinWorkingHoursErrorResponse>,
		AxiosResponse<WaitlistCodeInfoResponse>
	>({
		queryFn: () => APIVersion2GetWaitlistInfo({ waitlist_code }),
		queryKey: ["waitlist-info"],
		retry: 2,
		refetchInterval: 30000,
		staleTime: 30000,
	});

	UseQueryError({
		isError: getWaitlistInfoQuery.isError,
		onError: () => onError(getWaitlistInfoQuery.error),
	});

	React.useEffect(() => {
		if (getWaitlistInfoQuery.isSuccess) {
			setUser(getWaitlistInfoQuery.data.data);
		}
	}, [getWaitlistInfoQuery.isSuccess, getWaitlistInfoQuery.data, setUser]);

	return getWaitlistInfoQuery;
};

export const useGetStationJoinInfo = (
	onError: (
		error: AxiosError<JoinWorkingHoursErrorResponse> | null
	) => void = () => {
		return;
	}
) => {
	const { join_code } = useParams();

	const getJoinStationInfoQuery = useQuery<
		JoinWaitlistSingleStation,
		AxiosError<JoinWorkingHoursErrorResponse>
	>({
		queryFn: () => APIVersion2GetJoinInfo({ join_code }),
		queryKey: ["join-station-info"],
	});

	UseQueryError({
		isError: getJoinStationInfoQuery.isError,
		onError: () => onError(getJoinStationInfoQuery.error),
	});

	return getJoinStationInfoQuery;
};

export const useGetLocationJoinInfo = (
	onError: (
		error: AxiosError<JoinWorkingHoursErrorResponse> | null
	) => void = () => {
		return;
	}
) => {
	const { location_join_code } = useParams();

	const getJoinLocationInfoQuery = useQuery<
		AxiosResponse<JoinWaitlistLocationData>,
		AxiosError<JoinWorkingHoursErrorResponse>
	>({
		queryFn: () =>
			APIVersion2GetJoinInfo({ join_code: location_join_code }),
		queryKey: ["join-location-info"],
	});

	UseQueryError({
		isError: getJoinLocationInfoQuery.isError,
		onError: () => onError(getJoinLocationInfoQuery.error),
	});

	return getJoinLocationInfoQuery;
};

export const useJoinWaitlist = (
	onSuccess: (data: AxiosResponse<WaitlistCodeInfoResponse>) => void = () => {
		return;
	},
	onError: (error: any) => void = () => {
		return;
	}
) => {
	const navigate = useNavigate();
	const customToast = useCustomToast();
	const { reset, setUser } = useUserStore((s) => ({
		reset: s.reset,
		setUser: s.setUser,
	}));
	const { join_code } = useParams();

	return useMutation<
		AxiosResponse<WaitlistCodeInfoResponse>,
		AxiosError,
		JoinWaitlistType & { join_code?: string }
	>({
		mutationFn: (data) => APIVersion1JoinWaitlist({ ...data, join_code }),
		onSuccess: (data) => {
			reset();
			onSuccess(data);
			setUser(data.data);
			customToast("Joined waitlist 🎉", { id: "join-waitlist" });
			setTimeout(() => {
				navigate(
					"/waitlist/view/" + data.data.waitlist_info.track_code,
					{
						replace: true,
					}
				);
			}, 1000);
		},
		onError: (error) => {
			onError(error);
			customToast("Could not join waitlist 🤕", {
				id: "join-waitlist",
				type: "error",
			});
		},
	});
};

export const useUpdateWaitlist = (
	onSuccess: (data: AxiosResponse<WaitlistCodeInfoResponse>) => void = () => {
		return;
	},
	onError: (error: any) => void = () => {
		return;
	}
) => {
	const { waitlist_code } = useParams();
	const setUser = useUserStore((s) => s.setUser);

	return useMutation<
		AxiosResponse<WaitlistCodeInfoResponse>,
		AxiosError,
		UpdateWaitlistActionType & { waitlist_code?: string }
	>({
		mutationFn: (data) =>
			APIVersion1UpdateWaitlist({ ...data, waitlist_code }),
		onSuccess: (data) => {
			onSuccess(data);
			setUser(data.data);
		},
		onError: (data) => {
			onError(data);
		},
	});
};
