import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/src/components/ui/dialog";
import { useNavigate, useParams } from "react-router";
import useCustomToast from "@/src/components/CustomToast";
import { Button } from "@/src/components/ui/button";
import { LoaderButton } from "@/src/components/ui-extended/loader-button";
import { useUpdateAppointment } from "@/src/store/slices/scheduleSlice";
import { DialogDescription } from "@radix-ui/react-dialog";

const CancelAppointmentModal = ({ isOpen, setIsOpen, appointment_code }) => {
	const toast = useCustomToast();
	const navigate = useNavigate();
	const { mutate: updateAppointment, isPending } = useUpdateAppointment();

	const cancelAppointment = () => {
		updateAppointment(
			{
				appointmentId: appointment_code!,
				data: {
					action: "cancel",
				},
			},
			{
				onSuccess: () => {
					toast("Appointment cancelled successfully.", {
						type: "success",
						id: "cancelled-appointment",
					});
					setIsOpen(false);
					navigate(`/schedule/${appointment_code}/done`);
				},
				onError: () => {
					toast(
						"Failed to cancel the appointment. Please try again.",
						{
							type: "error",
							id: "cancelled-appointment",
						}
					);
				},
			}
		);
	};

	return (
		<Dialog
			open={isOpen}
			onOpenChange={setIsOpen}
			aria-describedby="appointment-cancellation-modal"
		>
			<DialogContent className="flex w-full max-w-[360px] rounded-[10px] px-3 py-4">
				<div className="flex gap-2">
					<i className="mgc_information_line mt-1.5 text-[20px] before:!text-main-4" />
					<div className="flex-1">
						<div className="flex flex-col gap-y-2">
							<DialogHeader>
								<DialogTitle className="flex items-center justify-between">
									<h1 className="text-xl font-semibold leading-[30px] tracking-[-0.22px]">
										Cancel Appointment
									</h1>
								</DialogTitle>
								<DialogDescription className="sr-only">
									Cancel your appointment
								</DialogDescription>
							</DialogHeader>
							<p className="text-sm tracking-[-0.1px] text-main-7">
								Are you sure you want to cancel the appointment?
								After cancelling appointment, all your
								information will be removed.{" "}
								<span className="font-semibold">
									This action can&apos;t be undone.
								</span>
							</p>
						</div>
						<DialogFooter className="mt-6 flex w-full !flex-row space-x-2.5">
							<Button
								className="flex-1"
								variant="outline"
								onClick={() => {
									if (!isPending) {
										setIsOpen(false);
									}
								}}
								disabled={isPending}
							>
								Undo
							</Button>
							<LoaderButton
								className="flex-1 text-white"
								type="submit"
								variant="destructive"
								loaderSize={20}
								onClick={cancelAppointment}
								loading={isPending}
								disabled={isPending}
							>
								Cancel Appointment
							</LoaderButton>
						</DialogFooter>
					</div>
					<DialogClose className="flex items-start">
						<i className="mgc_close_line mt-1.5 text-lg before:!text-main-4" />
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CancelAppointmentModal;
