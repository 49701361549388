import {
	useGetStationJoinInfo,
	useJoinWaitlist,
} from "@/src/store/slices/waitlist";
import { countryCodes } from "@/src/utils/general";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { LoaderButton } from "../ui-extended/loader-button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import CustomIntakeField, {
	createJoinWaitlistSchema,
} from "./CustomIntakeField";

const UserJoinInformation: React.FC = () => {
	const [countryCode, setCountryCode] = React.useState("+1");
	const getStationInfoQuery = useGetStationJoinInfo();
	const joinWaitlistMutation = useJoinWaitlist(undefined, () => {
		setError("root", {
			message: " Invalid details, kindly confirm all information 🤕",
		});
	});

	const [schema, setSchema] = React.useState(createJoinWaitlistSchema([]));

	const {
		register,
		handleSubmit,
		getValues,
		control,
		setError,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		// defaultValues: { custom_intakes: {} },
		resolver: zodResolver(schema),
	});

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
		joinWaitlistMutation.mutate({
			...data,
			phone_number: countryCode + data.phone_number,
			custom_intakes: customIntakesFormat(data),
		});
	};

	const customIntakesFormat = (
		data: z.infer<typeof schema>
	): Record<string, any> | undefined => {
		if (!data?.custom_intakes) return undefined;
		const fullCustomIntakes: Record<string, any> = {};

		getStationInfoQuery?.data?.data.custom_intakes.forEach((intake) => {
			if (!data?.custom_intakes) return undefined;
			const value = data?.custom_intakes[intake.key];

			switch (intake.type) {
				case "text":
				case "numeric":
					fullCustomIntakes[intake.key] = value;
					break;

				case "boolean":
					fullCustomIntakes[intake.key] = value;
					break;

				case "date":
					fullCustomIntakes[intake.key] =
						value instanceof Date
							? value.toISOString().split("T")[0]
							: value;
					break;

				case "date_range":
					if (
						value &&
						typeof value === "object" &&
						"from" in value &&
						"to" in value
					) {
						fullCustomIntakes[intake.key] = `${
							value.from instanceof Date
								? value.from.toISOString().split("T")[0]
								: value.from
						} - ${
							value.to instanceof Date
								? value.to.toISOString().split("T")[0]
								: value.to
						}`;
					}
					break;

				case "dropdown":
					fullCustomIntakes[intake.key] = value
						? value.map((v) => v.value)
						: undefined;

					break;
				case "checkbox":
					fullCustomIntakes[intake.key] = value;
					break;

				case "attachment":
					// Handle file attachment (you might need to implement file upload logic)
					fullCustomIntakes[intake.key] =
						value instanceof File ? value.name : value;
					break;

				default:
					fullCustomIntakes[intake.key] = value;
			}
		});
		console.log(fullCustomIntakes);
		return fullCustomIntakes;
	};

	React.useEffect(() => {
		if (
			getStationInfoQuery.data &&
			getStationInfoQuery.data.data.custom_intakes
		) {
			const newSchema = createJoinWaitlistSchema(
				getStationInfoQuery.data.data.custom_intakes
			);
			setSchema(newSchema);
		}
	}, [getStationInfoQuery.data]);
	console.log(errors);

	return (
		<section className="flex flex-col items-center space-y-16 p-4">
			<form
				className="w-full rounded-xl bg-white p-6"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex flex-col space-y-5">
					<h1 className="text-[20px] font-semibold leading-[140%] tracking-[-0.1px] text-main-1">
						{getStationInfoQuery.data?.data.station.name} at{" "}
						{getStationInfoQuery.data?.data.station.location.name}
					</h1>
					<p className="text-sm text-[#6D748D]">
						Enter your details to join the waitlist. We will only
						partially show your name for safety reasons.
					</p>
				</div>
				<div className="space-y-4 py-9">
					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Full Name{" "}
							<span className="text-destructive"> *</span>
						</Label>
						<Input className="h-10" {...register("full_name")} />
						{errors.full_name?.message && (
							<small className="text-sm text-destructive">
								{errors.full_name?.message as string}
							</small>
						)}
					</div>

					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Phone Number{" "}
							<span className="text-destructive"> *</span>
						</Label>
						<div className="flex h-10 items-stretch">
							<Select
								value={countryCode}
								onValueChange={(value) => {
									setCountryCode(value);
								}}
							>
								<SelectTrigger className="h-full w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
									<SelectValue
										className="text-[#858C95]"
										placeholder="+1"
									/>
								</SelectTrigger>
								<SelectContent className="!z-[9999]">
									<SelectGroup>
										<SelectLabel className="px-2">
											Country Codes
										</SelectLabel>

										{countryCodes.map((option) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
							<Input
								className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
								minLength={10}
								{...register("phone_number")}
								maxLength={10}
							/>
						</div>
						{errors.phone_number?.message && (
							<small className="text-sm text-destructive">
								{errors.phone_number?.message as string}
							</small>
						)}
					</div>
					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Email
						</Label>
						<Input {...register("email")} />
						{errors.email?.message && (
							<small className="text-sm text-destructive">
								{errors.email?.message as string}
							</small>
						)}
					</div>
					{getStationInfoQuery.data?.data.custom_intakes.map(
						(custom, idx) => (
							<CustomIntakeField
								key={idx}
								intake={custom}
								register={register}
								errors={errors}
								control={control}
							/>
						)
					)}
				</div>
				{errors.root?.message && (
					<small className="my-1.5 text-sm text-destructive">
						{errors.root?.message as string}
					</small>
				)}
				<p
					className="text-sm tracking-[0.07px] text-main-1"
					onClick={() => customIntakesFormat(getValues())}
				>
					By confirming, you accept our{" "}
					<a
						rel="noreferrer"
						href="https://migranium.com/terms"
						className="text-primary underline"
						target="_blank"
					>
						Terms
					</a>{" "}
					and{" "}
					<a
						rel="noreferrer"
						href="https://migranium.com/privacy-policy"
						className="text-primary underline"
						target="_blank"
					>
						Policies
					</a>
					.
				</p>
				<LoaderButton
					className="mt-8 w-full"
					disabled={joinWaitlistMutation.isPending}
					loading={joinWaitlistMutation.isPending}
					type="submit"
					loaderSize={20}
				>
					Confirm
				</LoaderButton>
			</form>
		</section>
	);
};

export default UserJoinInformation;
