import StationSelectionCard from "./StationSelectionCard";
import { Station } from "@/src/types/schedule";
import { useNavigate } from "react-router";

interface StationSelectionProps {
	stations?: Station[];
}
const StationSelection = ({ stations }: StationSelectionProps) => {
	const navigate = useNavigate();
	// const [selectedStation, setSelectedStation] =
	// 	useState<ScheduleStationInfo>();
	return (
		<div className="mt-10 flex flex-col space-y-2">
			<h2 className="font-bold leading-[140%] tracking-[-0.14px] text-main-1">
				Select Health Service
			</h2>

			{stations &&
				stations?.map((station) => (
					<StationSelectionCard
						key={station.id}
						station={station}
						onClick={() => {
							// setSelectedStation(station);
							navigate("/schedule/1/book-time");
						}}
					/>
				))}
		</div>
	);
};

export default StationSelection;
