import {
	JoinWaitlistType,
	UpdateWaitlistActionType,
} from "@/src/types/waitlist";
import { AxiosResponse } from "axios";
import { FieldValues } from "react-hook-form";
import $http from "../xhr";

export const APIVersion1ContactUs = async (
	data: FieldValues
): Promise<AxiosResponse<Record<string, string>>> =>
	$http.post("/admin/customer-contacts", data).then((res) => res.data);

export const APIVersion2GetWaitlistInfo = async (data: {
	waitlist_code?: string | null;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/status/${data.waitlist_code}`)
		.then((res) => res.data);

export const APIVersion2GetJoinInfo = async (data: {
	join_code?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/join/${data.join_code}/info`)
		.then((res) => res.data);

export const APIVersion1JoinWaitlist = async (
	data: JoinWaitlistType & { join_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/join/${data.join_code}`, {
			...data,
			join_code: undefined,
		})
		.then((res) => res.data);

export const APIVersion1UpdateWaitlist = async (
	data: UpdateWaitlistActionType & { waitlist_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/update/${data.waitlist_code}`, {
			...data,
			waitlist_code: undefined,
		})
		.then((res) => res.data);

/** == SCHEDULING ENDPOINTS == */

export const APIVersion2GetScheduleStationInfo = async (data: {
	scheduling_code?: string;
	station_id?: string;
}): Promise<any> =>
	$http
		.get(
			`v2/customer/schedule/${data.scheduling_code}/info ${data.station_id ? "?" + data.station_id : ""}`
		)
		.then((res) => res.data);

export const APIVersion2GetScheduleLocationInfo = async (data: {
	scheduling_code?: string;
	location_id?: string;
}): Promise<any> =>
	$http
		.get(
			`v2/customer/schedule/${data.scheduling_code}/info?${data.location_id}`
		)
		.then((res) => res.data);

export const APIVersion2GetScheduleStatus = async (data: {
	scheduling_code?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/schedule/status/${data.scheduling_code}`)
		.then((res) => res.data);

export const APIVersion2GetStationAvailableTimeSlotsForSchedule = async (
	stationId: number | undefined,
	data: {
		appointment_type_id?: number;
		date: string | undefined;
	}
): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.appointment_type_id && {
			appointment_type_id: data.appointment_type_id.toString(),
		}),
		date: data.date || "", // If date is undefined, use an empty string
	}).toString();

	return $http
		.get(
			`/v2/customer/schedule/available-blocks-for-scheduling/${stationId}?${queryParams}`
		)
		.then((res) => res.data);
};

export const APIVersion2CreateAppointment = async (
	scheduling_code: string,
	data
): Promise<any> =>
	$http
		.post(`/v2/customer/schedule/${scheduling_code}/schedule`, data)
		.then((res) => res.data);

export const APIVersion2UpdateAppointment = async (
	appointmentId: string,
	data: {
		action:
			| "done"
			| "cancel"
			| "reschedule"
			| "update_custom_intakes"
			| "check_in"
			| "undo_checkin";
		reschedule_date?: string;
		reschedule_time?: string;
		custom_intakes?: Record<string, any>;
	}
): Promise<any> =>
	$http
		.post("/v2/customer/schedule/update/" + appointmentId, data)
		.then((res) => res.data);
