import React, { useEffect, useMemo } from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import WaitListLayout from "../../components/Waitlist/WaitListLayout";
import { Button } from "@/src/components/ui/button";
import StationSelection from "./components/StationSelection";
import { useNavigate, useParams } from "react-router";
import { useScheduleStationInfo } from "@/src/store/slices/scheduleSlice";
import { ScheduleLocationData, Station } from "@/src/types/schedule";
import useScheduleStore from "@/src/store/useScheduleStore";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";

const Schedule: React.FC = () => {
	const { scheduling_code } = useParams();
	const { setStep } = useScheduleStore();
	const navigate = useNavigate();
	const { data, isSuccess, isLoading, error } = useScheduleStationInfo({
		scheduling_code,
	});
	const {
		setAppointmentLocationDetails,
		setAppointmentStationDetails,
		setScheduleCode,
	} = useScheduleStore();
	const is_station: boolean = useMemo(() => {
		return data?.data.available_time_slots ? true : false;
	}, [data]);

	useEffect(() => {
		setScheduleCode(scheduling_code ?? "");
		if (isSuccess && data) {
			if (data?.data.available_time_slots) {
				setAppointmentStationDetails(data.data);
			} else {
				setAppointmentLocationDetails(
					data.data as unknown as ScheduleLocationData
				);
			}
		}
	}, [isSuccess, data]);

	// console.log(data);
	return (
		<>
			<div className="bg-primary-3 h-screen overflow-hidden py-10 font-hoves md:pb-0">
				<WaitListLayout
					hasFooterButton={false}
					imageStyle="md:w-full w-[30%]"
					imageUrl={welcomeImage}
				>
					{isSuccess ? (
						<div className="mt-14 h-auto rounded-[12px] bg-white px-6 py-4 md:mt-0 md:w-[486px]">
							<h1 className="mb-3 text-[28px] font-semibold text-main-1 md:mb-0 lg:text-32px">
								Welcome!
							</h1>
							<div className="flex flex-col gap-y-2">
								<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
									<i className="mgc_building_1_line before:!text-primary" />
									<p className="text-sm font-semibold text-main-1">
										{data?.data?.station?.location.name}
									</p>
								</div>
								<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
									<i className="mgc_location_line before:!text-primary" />
									<p className="text-sm text-[#6D748D]">
										{data?.data?.station?.location.address}
									</p>
								</div>
								<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
									<i className="mgc_store_line before:!text-[#043B6D]" />
									<p className="text-sm text-main-1">
										{data?.data?.station?.name}
									</p>
								</div>
							</div>

							{!is_station ? (
								<div className="mt-10 w-full">
									<StationSelection
										stations={
											data?.data
												?.station as unknown as Station[]
										}
									/>
								</div>
							) : (
								<div className="mt-10 w-full">
									<Button
										type="button"
										className="w-full bg-[#043B6D]"
										onClick={() => {
											setStep(0);
											navigate(
												`/schedule/${scheduling_code}/create-appointment`
											);
										}}
									>
										Schedule Appointment
									</Button>
								</div>
							)}
						</div>
					) : (
						<div className="mt-8  rounded-[12px] bg-white p-4">
							<p className="text-center text-2xl font-semibold">
								No Information found for this code{" "}
							</p>
						</div>
					)}
				</WaitListLayout>
			</div>
			<RequestIsLoading
				isLoading={isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default Schedule;
