import React from "react";
import Navbar from "../components/Landing/Navbar";
import HeroSection from "../components/Landing/Hero";
import ContactUsModal from "../components/Landing/ContactUsModal";
import BookADemo from "../components/Landing/BookADemo";

const Home: React.FC = () => {
	const [showBookADemo, setShowBookDemo] = React.useState(false);
	const [showContactUsModal, setshowContactUsModal] = React.useState(false);
	return (
		<>
			<main className="flex h-[100dvh] flex-col">
				<Navbar
					showBookADemo={showBookADemo}
					setShowBookDemo={setShowBookDemo}
					showContactUsModal={showContactUsModal}
					setshowContactUsModal={setshowContactUsModal}
				/>
				<HeroSection
					setshowContactUsModal={setshowContactUsModal}
					setShowBookDemo={setShowBookDemo}
				/>
			</main>
			<ContactUsModal
				show={showContactUsModal}
				setShow={setshowContactUsModal}
			/>
			<BookADemo
				showBookADemo={showBookADemo}
				setShowBookDemo={setShowBookDemo}
			/>
		</>
	);
};

export default Home;
