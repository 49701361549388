import React from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import { useParams } from "react-router-dom";
import CustomInput from "../../components/Waitlist/CustomInput";
import { Button } from "@/src/components/ui/button";
import FormLayout from "./components/FormLayout";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { useCreateAppointment } from "@/src/store/slices/scheduleSlice";
import useScheduleStore from "@/src/store/useScheduleStore";
import useCustomToast from "@/src/components/CustomToast";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import { countryCodes } from "@/src/utils/general";
import { Input } from "@/src/components/ui/input";
import { Label } from "@/src/components/ui/label";
import { AxiosResponse } from "axios";
import Loader from "@/src/components/Loader/Loader";

interface DetailsFormProps {
	step: 0 | 1 | 2 | 3;
	setStep: (step: number) => void;
}

const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 11 characters" }),
	email: z
		.string()
		.email({ message: "Invalid email address format" })
		.nullable(),
});
type FormFields = z.infer<typeof schema>;

const DetailsForm = ({ step, setStep }: DetailsFormProps) => {
	const { scheduling_code } = useParams();
	const { formData, setFormData } = useFormContext();
	const { setSubmissionDetails } = useScheduleStore();
	const [countryCode, setCountryCode] = React.useState("+1");
	const toast = useCustomToast();
	const { mutate: createAppointment, isPending } = useCreateAppointment();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
	});

	const onSubmit: SubmitHandler<FormFields> = async (data) => {
		setFormData((prev) => ({
			...prev,
			patientDetails: data,
		}));
		createAppointment(
			{
				scheduling_code: scheduling_code ?? "",
				data: {
					full_name: data.name,
					phone_number: countryCode + data.phone,
					email: data.email,
					date: formData.appointmentDetails.date,
					start_time: formData.appointmentDetails.timeSlot.slice(
						0,
						-3
					),
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					setSubmissionDetails(data.data);
					setStep(2);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission-error",
					});
				},
			}
		);
	};

	return (
		<FormLayout step={step} setStep={setStep}>
			<div className="bg-primary-3 h-auto overflow-hidden py-8 font-hoves md:h-screen md:py-0">
				<div className="h-full flex-col items-center justify-between gap-y-4 px-4 md:flex md:gap-x-12 lg:flex-row lg:gap-x-24 lg:px-24">
					<div className=" hidden w-full justify-center md:flex md:h-full">
						<img
							className=" hidden w-[40%] md:block md:w-[50%] lg:w-[75%]"
							src={welcomeImage}
							alt=""
						/>
					</div>
					<div className="flex w-full justify-center">
						<div className=" h-auto rounded-[12px] bg-white px-6 pb-4 pt-10 text-main-4 sm:w-[441px] md:w-full md:max-w-[80%] lg:max-w-full lg:py-4">
							<p className="mt-7 text-[22px] text-lg font-semibold -tracking-1% text-main-1 md:mt-3 lg:leading-[30px]">
								Add your Information
							</p>
							<p className="mt-2 max-w-[294px] text-sm leading-5 tracking-0.5% text-main-7 lg:text-base lg:leading-[22.4px]">
								Only your first name will be shown on the
								waitlist for confidentiality.
							</p>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="mt-8 lg:mt-4">
									<CustomInput
										label="Full Name"
										error={errors.name?.message || ""}
										register={{ ...register("name") }}
										required
										input
									/>
								</div>
								<div className="mt-2.5">
									<div className="space-y-1.5">
										<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
											Phone Number{" "}
											<span className="text-destructive">
												{" "}
												*
											</span>
										</Label>
										<div className="flex h-10 items-stretch">
											<Select
												value={countryCode}
												onValueChange={(value) => {
													setCountryCode(value);
												}}
											>
												<SelectTrigger className="h-full w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
													<SelectValue
														className="text-[#858C95]"
														placeholder="+1"
													/>
												</SelectTrigger>
												<SelectContent className="!z-[9999]">
													<SelectGroup>
														<SelectLabel className="px-2">
															Country Codes
														</SelectLabel>

														{countryCodes.map(
															(option) => {
																return (
																	<SelectItem
																		key={
																			option.value
																		}
																		value={
																			option.value
																		}
																		className="px-8"
																	>
																		{
																			option.label
																		}
																	</SelectItem>
																);
															}
														)}
													</SelectGroup>
												</SelectContent>
											</Select>
											<Input
												className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
												minLength={10}
												{...register("phone")}
												maxLength={10}
											/>
										</div>
										{errors.phone?.message && (
											<small className="text-sm text-destructive">
												{
													errors.phone
														?.message as string
												}
											</small>
										)}
									</div>
								</div>
								<div className="mt-2.5">
									<CustomInput
										label="Email Address"
										error={errors.email?.message || ""}
										register={{ ...register("email") }}
										input
									/>
								</div>
								<p className="ld:text-base mb-8 mt-12 font-inter text-sm leading-[22.4px] tracking-0.5% text-main-4 md:mt-8">
									By confirming, you accept our{" "}
									<a
										className="border-b border-main-1 text-main-1"
										href=""
									>
										Terms
									</a>{" "}
									and{" "}
									<a
										className="border-b border-main-1 text-main-1"
										href=""
									>
										Policies
									</a>
									.
								</p>
								<div>
									<Button
										className=" w-full bg-primary text-white md:block"
										type="submit"
										disabled={isPending}
									>
										{isPending ? (
											<Loader size={15} />
										) : (
											"Schedule Appointment"
										)}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</FormLayout>
	);
};

export default DetailsForm;
