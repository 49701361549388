import { Button } from "@/src/components/ui/button";
import React from "react";

const ClosedWaitlist: React.FC = () => {
	return (
		<section className="mx-4 my-auto flex flex-col items-center space-y-3 rounded-xl bg-white p-12">
			<img
				src="/assets/graphics/icons/closed-waitlist.svg"
				alt="Closed waitlist"
				className="max-w-[105px] pb-12"
			/>
			<div className="flex flex-col space-y-6 pb-12">
				<div className="max-w-[310px] space-y-1">
					<h1 className="text-center text-xl font-semibold tracking-[-1%]  text-main-1">
						Sorry, we&apos;re currently closed!
					</h1>
					<p className="text-center text-sm tracking-[-0.5%] text-main-7">
						We are currently not taking anymore entries at this
						time. Please try again later or book an appointment.
					</p>
				</div>
			</div>
			<Button variant="ghost" className="mx-auto text-primary">
				Book Appointment
			</Button>
		</section>
	);
};

export default ClosedWaitlist;
