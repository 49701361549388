import { Label } from "@/src/components/ui/label";
import { Station } from "@/src/types/schedule";
import { ChevronRightIcon } from "@radix-ui/react-icons";

const StationSelectionCard: React.FC<{
	station: Station;
	onClick: () => void;
}> = ({ station, onClick }) => {
	return (
		<li
			className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-[#F1F3FC] p-2"
			onClick={onClick}
		>
			<div className="flex items-center justify-between space-x-1">
				<Label className="text-sm font-medium text-[#09090B]">
					{station.name}
				</Label>
				<ChevronRightIcon />
			</div>
			<p className="hidden text-xs text-main-7">
				Lorem ipsum dolor sit amet consectetur. Nunc diam ipsum faucibus
				mi nibh turpis elementum faucibus.
			</p>
		</li>
	);
};

export default StationSelectionCard;
